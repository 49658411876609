import React, { FC, Suspense, useEffect, useCallback, useState } from 'react';
import {
  useEnvironment,
  useErrorBoundary,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import {
  FormValues,
  type FormActions,
  type FormResults,
  type FormConfig,
} from '@wix/form-viewer';
import { FormViewerHandle, FormViewerContext } from '@wix/form-viewer/widget';
import { withSlotsPlaceholders } from '@wix/widget-plugins-ooi';
import { useSettings } from '@wix/tpa-settings/react';
import { classes, st } from './Widget.st.css';
import { FormControllerActions } from '../Actions/actions';
import { FormActionsProvider } from '../Hooks/useFormActions';
import {
  FormState,
  SlotService,
  ServiceData,
} from '../../../utils/state/types';
import BookingDetails from './BookingDetails/BookingDetails';
import EmptyStatePage from './EmptyStatePage/EmptyStatePage';
import { EditorContextProvider } from '../Hooks/useEditorContext';
import {
  CartConflictErrorType,
  EmptyStateErrorType,
  FormError,
  GenericErrorType,
} from '../../../types/errors';
import { PaymentSelectionContainer } from './PaymentSelectionContainer/PaymentSelectionContainer';
import BackButton from './BackButton/BackButton';
import {
  isNotOffered,
  isOfferedAsPricingPlanOnlyWithoutPlansConnected,
} from '../../../utils/payment';
import { WidgetDataHooks } from './dataHooks';
import ButtonsContainer from './ButtonsContainer';
import { PaymentSummary } from './PaymentSummary/PaymentSummary';
import { getErrorByType, hasErrorOfType } from '../../../utils/errors/errors';
import { FormStatus } from '../../../types/form-state';
import { FormComponentContextProvider } from '../Hooks/useFormComponentContext';
import CartModal from './CartModal/CartModal';
import useValidateWixForm from '../Hooks/useValidateWixForm';
import { CartConflictMessage } from './CartConflictMessage/CartConflictMessage';
import { CouponCheckbox } from './CouponCheckbox';
import NumberOfParticipantsDropdown from './NumberOfParticipantsDropdown/NumberOfParticipantsDropdown';
import { mapToArray } from '../../../utils';
import SmsNotification from './SmsNotification/SmsNotification';
import AlreadyBookedModal from './AlreadyBookedModal';
import WixForm from './WixForm';
import { ServiceInCartNotification } from './ServiceInCartNotification';

const Dialog = React.lazy(() => import('./Dialog/Dialog'));
const Toast = React.lazy(() => import('./Toast/Toast'));

const PolicyDescription = React.lazy(
  () => import('./PolicyDescription/PolicyDescription'),
);

const showGlobalLoaderList = [
  FormStatus.PROCESSING_SUBMIT_ACTION,
  FormStatus.REINITIALIZING,
];

export type ControllerProps = {
  actions: FormControllerActions;
} & FormState &
  WidgetProps & {
    wfActions: FormActions;
    wfConfig: FormConfig;
    wfResults: FormResults;
  };

const Widget: FC<WidgetProps<ControllerProps>> = (widgetProps) => {
  const {
    serviceData,
    actions,
    isPricingPlanInstalled,
    formSelectedSlot,
    businessInfo,
    isCart,
    couponInfo,
    memberDetails,
    errors,
    editorContext,
    status,
    dialog,
    cartModal,
    isServiceInCart,
    isUpsellPluginInstalled,
    existingBookingDetails,
    wixForms,
    selectedNumberOfParticipants,
    isSMSAutomationsEnabled,
  } = widgetProps;
  const { isMobile } = useEnvironment();
  const { error } = useErrorBoundary();
  const settings = useSettings();
  const dateRegionalSettingsLocale = businessInfo?.dateRegionalSettingsLocale!;
  const { validateWixForm } = useValidateWixForm();
  const wixFormRef = React.useRef<FormViewerHandle>(null);
  const [wixFormValues, setWixFormValues] = useState<FormValues>({});

  const handleWixFormChange = useCallback((values: FormValues) => {
    setWixFormValues(values);
  }, []);

  useEffect(() => {
    if (
      status === FormStatus.INITIALIZING ||
      status === FormStatus.REINITIALIZING
    ) {
      actions.initializeWidget();
    }
  }, [status, actions]);

  useEffect(() => {
    setWixFormValues({ ...wixFormValues, ...wixForms.initialValues });
  }, [wixForms.initialValues]);

  const renderedEmptyState = renderEmptyStatePage({
    error,
    status,
    errors,
    serviceData,
  });

  if (renderedEmptyState) {
    return renderedEmptyState;
  }

  const toastError = getErrorByType({
    errors,
    errorType: GenericErrorType,
  });

  const cartConflictError = getErrorByType({
    errors,
    errorType: CartConflictErrorType,
  });

  const showGlobalLoader = () => showGlobalLoaderList.includes(status);

  return (
    <FormViewerContext
      host={widgetProps.host}
      wfActions={widgetProps.wfActions}
      wfConfig={widgetProps.wfConfig}
      wfResults={widgetProps.wfResults}
    >
      <EditorContextProvider value={editorContext} key="form-main-widget">
        <FormActionsProvider
          value={{
            ...actions,
            validateWixForm: async () =>
              validateWixForm({ actions, serviceData, wixFormRef }),
          }}
        >
          <FormComponentContextProvider
            value={{
              wixFormValues,
              isCartConflictError: !!cartConflictError,
              isUpsellPluginInstalled,
              dateRegionalSettingsLocale,
            }}
          >
            <Suspense
              fallback={
                <RenderGlobalLoader
                  dataHook={WidgetDataHooks.SUSPENSE_GLOBAL_SPINNER}
                  showGlobalLoader
                />
              }
            >
              <div
                className={st(classes.root, {
                  isMobile,
                  isProcessing: showGlobalLoader(),
                })}
                data-hook={WidgetDataHooks.MAIN_CONTAINER}
              >
                <div className={classes.wrapper}>
                  <RenderGlobalLoader showGlobalLoader={showGlobalLoader()} />
                  {toastError ? <Toast toastError={toastError} /> : null}
                  <BackButton />
                  {cartConflictError ? (
                    <CartConflictMessage />
                  ) : (
                    <ServiceInCartNotification
                      isServiceInCart={isServiceInCart}
                    />
                  )}
                  <div className={classes.body}>
                    <div className={classes.formWrapper}>
                      <WixForm
                        formId={serviceData.formId}
                        wixFormRef={wixFormRef}
                        wixForms={wixForms}
                        formValues={wixFormValues}
                        handleFormChange={handleWixFormChange}
                      />
                      <NumberOfParticipantsDropdown serviceData={serviceData} />
                      <PaymentSelectionContainer
                        serviceData={serviceData}
                        memberDetails={memberDetails}
                        numberOfParticipants={selectedNumberOfParticipants}
                        isPricingPlanInstalled={isPricingPlanInstalled}
                        status={status}
                        errors={errors}
                      />
                    </div>
                    <div className={classes.sidebar}>
                      <div className={classes.floatingContainer}>
                        <BookingDetails
                          serviceData={serviceData}
                          formSelectedSlot={formSelectedSlot}
                          numberOfParticipants={selectedNumberOfParticipants}
                        />
                        <CouponCheckbox
                          serviceData={serviceData}
                          couponInfo={couponInfo}
                          isServiceInCart={isServiceInCart}
                        />
                        {serviceData.isSingleService && (
                          <PaymentSummary
                            serviceData={serviceData}
                            numberOfParticipants={selectedNumberOfParticipants}
                            status={status}
                          />
                        )}
                        <SmsNotification
                          isMSAutomationEnabled={isSMSAutomationsEnabled}
                        />
                        <PolicyDescription serviceData={serviceData} />
                        <ButtonsContainer
                          serviceData={serviceData}
                          isCart={isCart}
                          isServiceInCart={isServiceInCart}
                          errors={errors}
                          status={status}
                          selectedNumberOfParticipants={
                            selectedNumberOfParticipants
                          }
                          existingBookingDetails={existingBookingDetails}
                          memberDetails={memberDetails}
                        />
                      </div>
                    </div>
                  </div>
                  {dialog ? <Dialog {...dialog.props} /> : null}
                  <AlreadyBookedModal
                    existingBookingDetails={existingBookingDetails}
                    memberDetails={memberDetails}
                    serviceData={serviceData}
                  />
                  {cartModal?.status ? (
                    <CartModal
                      cartModalStatus={cartModal?.status}
                      serviceData={serviceData}
                      formSelectedSlot={formSelectedSlot}
                      bookingIds={cartModal.bookingIds}
                      settings={settings}
                    />
                  ) : null}
                </div>
              </div>
            </Suspense>
          </FormComponentContextProvider>
        </FormActionsProvider>
      </EditorContextProvider>
    </FormViewerContext>
  );
};

const RenderGlobalLoader = ({
  showGlobalLoader,
  dataHook,
}: {
  showGlobalLoader: boolean;
  dataHook?: string;
}) => {
  if (!showGlobalLoader) {
    return null;
  }
  return (
    <>
      <div
        className={st(classes.blanket)}
        data-hook={dataHook ? dataHook : WidgetDataHooks.GLOBAL_SPINNER}
      />
      <EmptyStatePage isProcessing={showGlobalLoader} />
    </>
  );
};

const renderEmptyStatePage = ({
  status,
  errors,
  error,
  serviceData,
}: {
  status: FormStatus;
  errors: FormError[];
  error?: Error | null;
  serviceData?: ServiceData;
}) => {
  const processingStatuses = [
    FormStatus.INITIALIZING,
    FormStatus.PROCESSING_USER_DETAILS,
    FormStatus.SSR,
  ];
  const isProcessing = processingStatuses.includes(status);

  const shouldShowEmptyStatePage = () =>
    isProcessing ||
    hasErrorOfType({ errorType: EmptyStateErrorType, errors }) ||
    error;

  if (shouldShowEmptyStatePage()) {
    if (error) {
      console.error('Empty state page rendered', error, errors);
    }
    return <EmptyStatePage isProcessing={isProcessing} />;
  }

  const someServiceNotValid =
    !serviceData?.slotServices ||
    mapToArray<SlotService>(serviceData.slotServices).some(
      ({ service }) =>
        isOfferedAsPricingPlanOnlyWithoutPlansConnected(service?.payment) ||
        isNotOffered(service?.payment),
    );

  if (someServiceNotValid) {
    console.error(
      'Empty state page rendered: some service not valid',
      error,
      errors,
    );
    return (
      <EmptyStatePage
        isProcessing={isProcessing}
        title="app.empty-state-page.no-pp.title"
        subtitle="app.empty-state-page.no-pp.subtitle"
      />
    );
  }

  return null;
};

export default withSlotsPlaceholders(Widget);
