import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { classes, st } from './BookingDetails.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { getContent } from '../../../../utils/content/content';
import { ServiceData } from '../../../../utils/state/types';
import { FormSelectedSlot } from '../../../../types/formSelectedSlots';
import { getServiceSlotIdentifier } from '../../../../utils';
import SingleSlotDetails from './SingleSlotDetails/SingleSlotDetails';
import { Accordion } from 'wix-ui-tpa/cssVars';
import { BookingDetailsDataHook } from './dataHooks';

export interface BookingDetailsProps {
  serviceData: ServiceData;
  formSelectedSlot: FormSelectedSlot;
  numberOfParticipants?: number;
}

const SERVICE_DETAILS_TITLE = 'service-details';

const BookingDetails: React.FC<BookingDetailsProps> = ({
  serviceData,
  formSelectedSlot,
  numberOfParticipants,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  const sectionTitle = getContent({
    settings,
    settingsParam: settingsParams.summarySectionTitle,
  });

  return (
    <div className={st(classes.root, { isMobile })}>
      <Accordion
        initiallyExpanded={isMobile ? '' : SERVICE_DETAILS_TITLE}
        className={classes.accordion}
        data-hook={BookingDetailsDataHook.ACCORDION}
      >
        <Accordion.Item id={SERVICE_DETAILS_TITLE} title={sectionTitle}>
          {formSelectedSlot.nestedSlots.map((slot, index, slots) => {
            const slotService =
              serviceData.slotServices[getServiceSlotIdentifier(slot)];
            return (
              <div className={index < slots.length ? classes.item : ''}>
                <SingleSlotDetails
                  key={getServiceSlotIdentifier(slot)}
                  isSingleService={serviceData.isSingleService}
                  slot={slot}
                  slotService={slotService}
                  totalNumberOfParticipants={numberOfParticipants}
                />
              </div>
            );
          })}
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default BookingDetails;
